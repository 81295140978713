import React, { useRef } from "react";

interface IModalWindow {
  onCloseButtonClick: () => void;
  isShow: boolean;
  header: string;
  children?: React.ReactNode;
  className?: string;
}

const ModalWindow: React.FC<IModalWindow> = ({onCloseButtonClick, header, className, isShow, children }) => {
  let modalRef = useRef<HTMLDivElement | null>(null)

  return (
    <div
      ref={modalRef}
      className={`bg-black text-white rounded-3xl
      shadow-[0_0_0_100dvh_rgba(0,0,0,0.5)] modal-window 
      m-auto absolute top-0 bottom-0 left-0 right-0 z-40 flex flex-col
      ${className} ${isShow ? '' : 'hidden'}`}
    >
      <div className="w-full flex justify-between mt-6 mb-2 pr-4 text-2xl">
        <div className={"text-3xl font-bold pl-4 leading-6"}>
          <span>
            {header}
          </span>
        </div>
        <button
          className={"w-6 h-6 bg-gray-600 rounded-full text-xl leading-6"}
          onClick={() => {
            onCloseButtonClick()
          }}
        >
          &#215;
        </button>
      </div>
      <div className={"content flex flex-col"}>
        {children}
      </div>
    </div>
  )
};

export default ModalWindow;
