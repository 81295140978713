import ApiWrapper from './apiWrapper';
import {
  IReceiptModel,
  PurchaseCardRequest,
  StartTaskRequest,
  FinishTaskRequest,
  IUserModel,
  InitialDataResponse,
  SetCharacterRequest,
  GetFriendsResponse,
  GetStatsResponse, IStealCoinsResponse,
} from "../types/types";

const api = new ApiWrapper();

/**
 * Purchase a card
 * POST /api/cards
 */
export const purchaseCardApi = async (request: PurchaseCardRequest): Promise<IReceiptModel> => {
  return api.post<IReceiptModel>('/cards', request);
};

/**
 * Start a task
 * POST /api/task/start
 */
export const startTaskApi = async (request: StartTaskRequest): Promise<void> => {
  return api.post<void>('/task/start', request);
};

/**
 * Finish a task
 * POST /api/task/finish
 */
export const finishTaskApi = async (request: FinishTaskRequest): Promise<void> => {
  return api.post<void>('/task/finish', request);
};

/**
 * Get initial data
 * GET /api/user
 */
export const getInitialDataApi = async (referredBy?: string): Promise<InitialDataResponse> => {
  const config = referredBy ? { headers: { 'Referred-By': referredBy } } : undefined;
  return api.get<InitialDataResponse>('/user', config);
};

/**
 * Updates user level
 * POST /api/user/level
 */
export const updateUserLevel = async (): Promise<void> => {
  return api.put<void>('/user/level');
};

/**
 * Set user character
 * POST /api/user/set-character
 */
export const setUserCharacterApi = async (request: SetCharacterRequest): Promise<IUserModel> => {
  return api.post<IUserModel>('/user/set-character', request);
};

/**
 * Start mining
 * POST /api/user/mining/start
 */
export const startMiningApi = async (): Promise<IUserModel> => {
  return api.post<IUserModel>('/user/mining/start');
};

/**
 * Claim coins (finish mining)
 * POST /api/user/mining/finish
 */
export const claimCoinsApi = async (): Promise<IUserModel> => {
  return api.post<IUserModel>('/user/mining/finish');
};

/**
 * Get friends
 * GET /api/user/friends
 */
export const getFriendsApi = async (): Promise<GetFriendsResponse> => {
  return api.get<GetFriendsResponse>('/user/friends');
};

/**
 * Get stats
 * GET /api/user/stats
 */
export const getStatsApi = async (): Promise<GetStatsResponse> => {
  return api.get<GetStatsResponse>('/user/stats');
};

/**
 * Claim daily bonus
 * GET /api/user/claim-bonus
 */
export const claimBonusApi = async (): Promise<IUserModel> => {
  return api.get<IUserModel>('/user/claim-bonus');
};

/**
 * Steal c
 * GET /api/user/steal
 */
export const stealCoinsApi = async (): Promise<IStealCoinsResponse> => {
  return api.get<IStealCoinsResponse>('/user/steal');
};
