import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  ICardModel,
  ICardRequirementModel,
  ICardSectionModel, IDailyBonusModel,
  InitialDataResponse,
  IReceiptModel, IRequireable,
  ITaskDTO,
  IUserBoostCard,
  IUserBoostCardSection,
  IUserModel, IUserSafeModel,
  RequirementType
} from "./types/types";
import { useTranslation } from "react-i18next";
import { Pages } from "./app/pages";
import ProgressLoader from "./components/_components/ProgressLoader/ProgressLoader";
import CharacterPicker from "./components/_components/CharacterPicker/CharacterPicker";
import NavBar from "./components/_components/NavBar/NavBar";
import { mapRequirementType } from "./utils/transformation";
import {
  mapBoostCardSectionsDTOToUserBoostCardSections, mapCardModelsToUserBoostCards
} from "./utils/boostCard";
import { coinsToNextLevel } from "./constants/coinsToNextLevel";
import { getNextLevelCoinsAmount } from "./utils/money";
import { claimBonusApi, claimCoinsApi, getInitialDataApi, startMiningApi, updateUserLevel } from "./api/apiFunctions";

const App: React.FC = () => {
  const maxLevel = coinsToNextLevel.length;

  const navigate = useNavigate();
  const [t] = useTranslation();

  const [user, setUser] = useState<IUserModel | null>(null);
  const [thief, setThief] = useState<IUserSafeModel | null>(null);
  const [isCharacterChosen, setIsCharacterChosen] = useState<boolean>(false);
  const [isMiningStarted, setIsMiningStarted] = useState<boolean | null>(null);
  const [isMiningFinished, setIsMiningFinished] = useState<boolean | null>(null);
  const [coins, setCoins] = useState(0);
  const [timeToUnlockBonusButton, setTimeToUnlockBonusButton] = useState<string | null>(null);
  const [greetingsShowed, setGreetingsShowed] = useState(false);

  const [tasks, setTasks] = useState<ITaskDTO[]>([]);
  const [dailyBonusModels, setDailyBonusModels] = useState<IDailyBonusModel[] | null>(null);
  const [cardRequirements, setCardRequirements] = useState<ICardRequirementModel[] | null>(null);
  const [userBoostCardSections, setUserBoostCardSections] = useState<IUserBoostCardSection[] | null>(null);
  const [lastUserCardPurchaseReceipts, setLastUserCardPurchaseReceipts] = useState<IReceiptModel[] | null>(null);
  const [recentUserCardPurchaseReceipts, setRecentUserCardPurchaseReceipts] = useState<IReceiptModel[] | null>(null);

  const [initialized, setInitialized] = useState(false);

  const [activePage, setActivePage] = useState<string>(Pages.HOME);

  const claimBonus = async (dailyBonusModel: IDailyBonusModel) => {
    try {
      const userModel = await claimBonusApi();
      if (userModel) {
        setUser(userModel);
      }
    } catch (e) {
      console.error(e);
    }
  }

  const refreshCards = (receipts: IReceiptModel[]) => {
    console.log("Refreshing cards with receipts:", receipts);
    if (!userBoostCardSections || !user) return;

    const userBoostCardSectionsCopy = [...userBoostCardSections];
    if (!userBoostCardSectionsCopy || userBoostCardSections.length === 0) return;

    if (receipts.length > 0) {
      for (let section of userBoostCardSectionsCopy) {
        for (let userCard of section.cards) {
          const receipt = receipts.find(r => r.cardId === userCard.id);
          if (receipt) {
            console.log("Updating card using receipt:", userCard, receipt);
            updateCardUsingReceipt(userCard, receipt);
          }
        }
      }
    }
    checkRequirements(user, userBoostCardSectionsCopy, [...recentUserCardPurchaseReceipts ?? [], ...receipts]);
  }

  const updateCardUsingReceipt = (card: IUserBoostCard, receipt: IReceiptModel) => {
    console.log("Updating card level and boost:", card, receipt);
    card.level = receipt.level;
    card.price = Math.round(receipt.nextLevelPrice);
    card.totalBoost = receipt.totalCardBoost * 3600;
    card.nextLevelBoost = (receipt.nextLevelBoost + receipt.totalCardBoost) * 3600;
  }

  const addReceiptsToReceiptStates = (receipts: IReceiptModel[]) => {
    console.log("Adding receipts to state:", receipts);
    const cardMap = new Map<number, IReceiptModel>();
    setRecentUserCardPurchaseReceipts([...recentUserCardPurchaseReceipts ?? [], ...receipts]);

    receipts = [...lastUserCardPurchaseReceipts ?? [], ...receipts];
    receipts.forEach((receipt) => {
      const existingCard = cardMap.get(receipt.cardId);
      if (!existingCard || receipt.level > existingCard.level) {
        cardMap.set(receipt.cardId, receipt);
      }
    });
    receipts = Array.from(cardMap.values());

    setLastUserCardPurchaseReceipts(receipts);
  }

  const checkRequirement = (
    cardRequirement: ICardRequirementModel | undefined | null,
    model: IRequireable,
    userModel: IUserModel,
    receipts: IReceiptModel[]) => {

    if (cardRequirement) {
      switch (mapRequirementType(cardRequirement.type)) {
        case RequirementType.CARD: {
          const lastCardReceipt = receipts.find(r => r.cardId === cardRequirement.requirementOwnerId && r.level >= cardRequirement.cardLevel!);

          if (!lastCardReceipt) {
            model.requirement = cardRequirement;
          } else {
            model.requirement = null;
          }
          break;
        }
        case RequirementType.TASK: {
          if (!userModel.finishedTasksIds || userModel.finishedTasksIds.length === 0 || !userModel.finishedTasksIds.some(fT => fT === cardRequirement.requirementOwnerId!)) {
            model.requirement = cardRequirement;
          } else {
            model.requirement = null;
          }
          break;
        }
        default:
          break;
      }
    } else {
      model.requirement = null;
    }
  }

  const checkRequirements = (
    userModel: IUserModel,
    userCardSections: IUserBoostCardSection[],
    receipts: IReceiptModel[]
  ) => {
    console.log("Checking requirements for user model and card sections");
    if (cardRequirements && userCardSections && userCardSections.length > 0) {

      for (const s of userCardSections) {
        const sectionRequirement = cardRequirements.find(r => r.sectionId === s.id);
        checkRequirement(sectionRequirement, s, userModel, receipts);
        const sectionCards = s.cards;
        if (sectionCards) {
          for (const c of sectionCards) {
            const cardRequirement = cardRequirements.find(r => r.cardId === c.id);
            checkRequirement(cardRequirement, c, userModel, receipts);
            if ((!cardRequirement || !c.requirement) && s.requirement) {
              checkRequirement(s.requirement, c, userModel, receipts);
            }
          }
        }
      }
      console.log(userCardSections)
      setUserBoostCardSections(userCardSections);
    }
  }

  const handleMining = async () => {
    if (user === null) return;

    try {
      if (!isMiningStarted) {
        console.log("Starting mining...");
        const userModel = await startMiningApi();
        console.log("Mining started. Updated user model:", userModel);
        setUser(userModel);
        setIsMiningStarted(true);
      } else {
        console.log("Claiming coins...");
        const userModel = await claimCoinsApi();
        console.log("Coins claimed. Updated user model:", userModel);
        setUser(userModel);
        setIsMiningStarted(false);
        setIsMiningFinished(null);
        setCoins(0);
      }
    } catch (e) {
      console.error("Error during mining process:", e);
    }
  };

  const calculateMinedCoins = (miningStarted: number, timestamp: number) => {
    // console.log("Calculating mined coins from start time to timestamp:", miningStarted, timestamp);
    if (recentUserCardPurchaseReceipts === null) return 0;

    const receiptsInRange = recentUserCardPurchaseReceipts.filter(r => r.timestamp >= miningStarted && r.timestamp <= timestamp).sort((a, b) => a.timestamp - b.timestamp);
    const miningPower = user?.miningStartPower;
    let minedCoins = 0;
    if (miningPower && timestamp > miningStarted) {
      minedCoins = (timestamp - miningStarted) * miningPower;
      for (const receipt of receiptsInRange) {
        minedCoins = minedCoins + (receipt.boost * (timestamp - receipt.timestamp));
      }
    }
    return minedCoins;
  }

  const processUserModel = (userModel: IUserModel) => {
    console.log("Processing user model:", userModel);
    setUser(userModel);
    setIsCharacterChosen(userModel?.race !== "");

    if (userModel.miningStart) {
      setIsMiningStarted(true);
      if (userModel.miningFinish) {
        setIsMiningFinished(new Date(userModel.miningFinish) <= new Date());
      }
    } else {
      setIsMiningStarted(false);
    }
  }

  const processThiefModel = (userModel: IUserSafeModel) => {
    setThief(userModel);
  }

  const processBoostCards = (
    rawCardSections: ICardSectionModel[],
    rawCards: ICardModel[]
  ) => {
    console.log("Processing boost cards. Raw sections and cards:", rawCardSections, rawCards);
    const userCardSections: IUserBoostCardSection[] = mapBoostCardSectionsDTOToUserBoostCardSections(rawCardSections);
    const userCards: IUserBoostCard[] = mapCardModelsToUserBoostCards(rawCards);

    for (const s of userCardSections) {
      const sectionCards = userCards.filter(c => c.sectionId === s.id);
      if (sectionCards) {
        s.cards = sectionCards;
      }
    }

    setUserBoostCardSections(userCardSections);
  }

  const processReceipts = (receipts: IReceiptModel[]) => {
    console.log("Processing receipts:", receipts);
    if (receipts) {
      addReceiptsToReceiptStates(receipts);
    }
  }

  const processTasks = (tasks: ITaskDTO[]) => {
    console.log("Processing tasks:", tasks);
    const sortedTasks = tasks.sort((tA, tB) => tA.reward - tB.reward);
    setTasks(sortedTasks);
  }

  const processRequirements = (requirements: ICardRequirementModel[]) => {
    console.log("Processing requirements:", requirements);
    setCardRequirements(requirements);
  }

  const processDailyBonuses = (dailyBonusModels: IDailyBonusModel[]) => {
    console.log("Processing daily bonus models:", dailyBonusModels);
    setDailyBonusModels(dailyBonusModels);
  }

  const processInitialData = (initialDataResponse: InitialDataResponse) => {
    console.log("Processing initial data response:", initialDataResponse);
    const userModel = initialDataResponse.user;
    const thiefModel = initialDataResponse.thief;
    const dailyBonuses = initialDataResponse.dailyBonus;
    const receipts = initialDataResponse.receiptModels;
    const requirements = initialDataResponse.requirements;
    const rawCardSections = initialDataResponse.cardSections;
    const rawCards = initialDataResponse.cards;
    const rawTasks = initialDataResponse.tasks;

    processUserModel(userModel);
    processThiefModel(thiefModel);
    processDailyBonuses(dailyBonuses);
    processReceipts(receipts);
    processRequirements(requirements);
    processBoostCards(rawCardSections, rawCards);
    processTasks(rawTasks);
  }

  const refreshMiningData = () => {
    console.log("Refreshing mining data...");
    if (user === null || !(user.miningStart)
      || !user.miningPower || isMiningStarted === null || !recentUserCardPurchaseReceipts) return;

    if (isMiningStarted && user.miningFinish) {
      const dateNow = new Date().getTime() / 1000;
      const miningStart = new Date(user.miningStart).getTime() / 1000;
      const miningFinish = new Date(user.miningFinish).getTime() / 1000;

      if (dateNow >= miningFinish) {
        console.log("Mining finished. Calculating mined coins...");
        setIsMiningFinished(true);
        const minedCoins = calculateMinedCoins(miningStart, miningFinish);
        if(thief){
          setCoins(minedCoins - user.lostCoins);
        }else{
          setCoins(minedCoins)
        }
      } else {
        console.log("Mining in progress. Calculating mined coins...");
        setCoins(calculateMinedCoins(miningStart, dateNow));
        setIsMiningFinished(false);
      }
    } else {
      setIsMiningFinished(null);
    }
    console.log("Mining data refreshed");
  }

  useEffect(() => {
    console.log("Checking if app is initialized:", initialized);
    if (!initialized && user && cardRequirements && userBoostCardSections && lastUserCardPurchaseReceipts && dailyBonusModels) {
      refreshCards(lastUserCardPurchaseReceipts);
      setInitialized(true);
    }
  }, [user, cardRequirements, userBoostCardSections, lastUserCardPurchaseReceipts, dailyBonusModels]);

  useEffect(() => {
    console.log("User or receipts changed. Refreshing cards:", user, lastUserCardPurchaseReceipts);
    if (initialized && lastUserCardPurchaseReceipts && lastUserCardPurchaseReceipts.length > 0) {
      refreshCards(lastUserCardPurchaseReceipts);
    }
  }, [user, lastUserCardPurchaseReceipts]);

  useEffect(() => {
    console.log("User coins changed:", user?.coins);
    if (user === null) return;
    let userLevel = user.level;
    let levelChanged = false;
    while (user.coins >= getNextLevelCoinsAmount(userLevel) && userLevel < maxLevel && userLevel >= user.level) {
      userLevel = userLevel + 1;
      levelChanged = true;
    }
    if (levelChanged) {
      console.log("User level changed. Updating level to:", userLevel);
      updateUserLevel().then(() => setUser({ ...user, level: userLevel }));
    }
  }, [user?.coins]);

  useEffect(() => {
    console.log("Mining state changed. Mining started:", isMiningStarted, "Mining finished:", isMiningFinished);
    if (user === null || !isMiningStarted || isMiningFinished || user.miningPower === null || user.miningFinish === null) {
      return;
    }

    const miningFinishTime = new Date(user.miningFinish).getTime();
    const miningInterval = setInterval(() => {
      const currentTime = new Date().getTime();
      if (currentTime < miningFinishTime) {
        console.log("Mining in progress. Increasing coins...");
        refreshMiningData();
        // setCoins((prevCoins) => prevCoins + user.miningPower);
      } else {
        console.log("Mining finished. Stopping interval.");
        setIsMiningFinished(true);
        clearInterval(miningInterval);
      }
    }, 1000);

    return () => {
      clearInterval(miningInterval);
    };
  }, [isMiningStarted, isMiningFinished, user?.miningPower, user?.miningFinish]);

  useEffect(() => {
    console.log("Refreshing mining data after state change");
    refreshMiningData();
  }, [isMiningStarted, recentUserCardPurchaseReceipts, user?.miningPower, user?.miningStart]);

  useEffect(() => {
    Telegram.WebApp.setHeaderColor('#000000');
    Telegram.WebApp.setBackgroundColor('#000000');
    Telegram.WebApp.setBottomBarColor('#000000');
  }, []);

  useEffect(() => {
    console.log("Fetching initial data...");
    if (user === null) {
      try {
        if (Telegram.WebApp.initData) {
          getInitialDataApi(Telegram.WebApp.initDataUnsafe.start_param).then(
            response => processInitialData(response)
          ).finally(() => {
            console.log("Initial data loaded");
          });
        } else {
          navigate('/');
        }
      } catch (error) {
        console.error("Error fetching initial data:", error);
      }
    }
  }, [navigate, Telegram.WebApp.initData]);

  useEffect(() => {
    console.log("Navigating to active page:", activePage);
    navigate(activePage);
  }, [activePage]);

  useEffect(() => {
    const handleFocus = () => {
      if (document.visibilityState === 'visible') {
        console.log("Tab is focused. Refreshing mining data.");
        refreshMiningData();
      }
    };
    document.addEventListener('visibilitychange', handleFocus);

    return () => {
      document.removeEventListener('visibilitychange', handleFocus);
    };
  }, []);

  if (!initialized) {
    return <ProgressLoader startScreen />;
  }

  if (!user) {
    return <div>{t('app.messages.pleaseLogin')}</div>;
  }

  if (!isCharacterChosen) {
    return <CharacterPicker
      setUser={setUser}
      setIsCharacterChosen={setIsCharacterChosen}
    />
  }

  return (
    <div className="app bg-black min-h-dvh">
      <nav className="fixed bottom-6 w-full h-16 px-4 z-40">
        <NavBar
          activePage={activePage}
          setActivePage={setActivePage}
        />
      </nav>
      <main>
        <Outlet
          context={{
            user,
            setUser,
            thief,
            greetingsShowed,
            setGreetingsShowed,
            userBoostCardSections,
            addReceiptsToReceiptStates,
            coins,
            isMiningStarted,
            isMiningFinished,
            handleMining,
            tasks,
            setTasks,
            dailyBonusModels,
            claimBonus,
            timeToUnlockBonusButton,
            setTimeToUnlockBonusButton
          }}
        />
      </main>
    </div>
  );
}

export default App;
