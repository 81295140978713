import { IInternalChatMessage } from "../../../types/types";
import { useTranslation } from "react-i18next";
import { useEffect, useRef } from "react";
import parse from 'html-react-parser';

interface IInternalChatProps {
  messages: IInternalChatMessage[];
}

const InternalChat = ({ messages }: IInternalChatProps) => {
  const { t } = useTranslation();
  const lastMessageRef = useRef<HTMLDivElement>(null); // Define the correct ref type

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  return (
    <div className="chat-container">
      {messages.map((m, i) => (
        <div
          key={i}
          ref={i === messages.length - 1 ? lastMessageRef : null}
          className="chat-message"
        >
          <span className="font-semibold uppercase">
            {t(`app.internalChat.${m.incoming ? "courtier" : "you"}`)}
          </span>
          <span> {parse(m.text)}</span>
          <br />
        </div>
      ))}
    </div>
  );
};

export default InternalChat;
