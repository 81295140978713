import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { IContext, IThiefModel, IUserSafeModel } from "../../types/types";
import { useTranslation } from "react-i18next";
import ProgressLoader from "../_components/ProgressLoader/ProgressLoader";
import { getStatsApi } from "../../api/apiFunctions";
import UserListItem from "../_components/UserListItem/UserListItem";
import ThiefListItem from "../_components/UserListItem/ThiefListItem";

const Stats = () => {
  const [t] = useTranslation();
  const { user } = useOutletContext<IContext>();
  const [totalTopWorldUsers, setTotalTopWorldUsers] = useState<number>(0);
  const [topWorldUserModels, setTopWorldUserModels] = useState<IUserSafeModel[]>([]);
  const [totalTopInLevelUsers, setTotalTopInLevelUsers] = useState<number>(0);
  const [topInLevelUserModels, setTopInLevelUserModels] = useState<IUserSafeModel[]>([]);
  const [topThiefModels, setTopThiefModels] = useState<IThiefModel[]>([]);
  const [currentSection, setCurrentSection] = useState<number>(0);
  const [loading, setLoading] = useState(true);

  const onSectionClick = (sectionId: number) => {
    setCurrentSection(sectionId);
  }

  useEffect(() => {
    const fetchStats = async () => {
      const getStatsResponse = await getStatsApi();
      setTotalTopWorldUsers(getStatsResponse.totalTopWorldUsers);
      setTopWorldUserModels(getStatsResponse.topWorldUserModels);
      setTotalTopInLevelUsers(getStatsResponse.totalTopInLevelUsers);
      setTopInLevelUserModels(getStatsResponse.topInLevelUserModels);
      setTopThiefModels(getStatsResponse.thiefModels);
    };

    if (loading) {
      fetchStats().finally(() => setLoading(false));
    }
  }, [loading]);

  if (loading) {
    return <ProgressLoader />
  }

  return (
    <div className="stats p-4 pb-24">
      <h2 className="text-3xl font-semibold mb-4">{t('app.stats.')}</h2>
      <div className="flex justify-around gap-2 p-2 items-center w-full rounded-2xl shadow-md border-t bg-stone-800 border-gray-700 z-50 sticky top-6 appearance-left">
        <button
          onClick={() => onSectionClick(0)}
          className={`w-full py-1 rounded-lg transition-colors ${currentSection === 0 ? 'bg-gray-600 text-white' : 'text-gray-500'}`}
        >
          {t(`app.stats.topWorld`)}
        </button>
        <button
          onClick={() => onSectionClick(1)}
          className={`w-full py-1 rounded-lg transition-colors ${currentSection === 1 ? 'bg-gray-600 text-white' : 'text-gray-500'}`}
        >
          {t(`app.stats.topInLevel`, { value: user.level })}
        </button>
        <button
          onClick={() => onSectionClick(2)}
          className={`w-full py-1 rounded-lg transition-colors ${currentSection === 2 ? 'bg-gray-600 text-white' : 'text-gray-500'}`}
        >
          {t(`app.stats.topThieves`)}
        </button>
      </div>
      {
        currentSection >= 0 && currentSection <= 1 && (
          <p className="text-xl mt-2 appearance-top">{t('app.stats.totalUsers', { value: currentSection === 0 ? totalTopWorldUsers : totalTopInLevelUsers })}</p>
        )
      }
      <h3 className="text-xl font-semibold mt-4 mb-2 appearance-left">{t('app.stats.topUsers')}</h3>
      <ul className={"flex flex-col gap-2 appearance-bottom"}>
        {currentSection === 0 && topWorldUserModels.map((topUser) =>
          <UserListItem
            key={topUser.id}
            userSafeModel={topUser}
            userId={user.id}
          />
        )}
        {currentSection === 1 && topInLevelUserModels.map((topUser) =>
          <UserListItem
            key={topUser.id}
            userSafeModel={topUser}
            userId={user.id}
          />
        )}
        {currentSection === 2 && topThiefModels.map((topUser) =>
          <ThiefListItem
            key={topUser.id}
            thiefModel={topUser}
            userId={user.id}
          />
        )}
      </ul>
    </div>
  );
};

export default Stats;
